import { defineStore } from 'pinia';
import { TARGET_ORIGIN } from '@/constants';
import { logger } from '@/plugins/logger';
import { SetMenuItemsData } from '../navBar/interface';
import type { DropdownInterface } from 'flowbite';
import {
  EventData,
  HandShakeEventData,
  MeEventData,
  MicroFrontend,
  MicroFrontendSpecification,
  MicroFrontendState as State,
  NavigateEventData,
  PushHistoryEventData,
} from './interface';
import * as Sentry from '@sentry/vue';
import { useNavBarStore } from '../navBar';
import { validChildOperations } from './validChildOperations';
import { useUserDataStore } from '@upmetrics-platform/common-ui';
import { toRaw } from 'vue';

const handShakeEventData = (): HandShakeEventData => {
  const { user, permissions, impersonation, org, edition, currentSession } =
    useUserDataStore();
  if (!user) logger.debug('The store should have the user.');
  if (!permissions) logger.debug('The store should have the permissions.');
  if (!org) logger.debug('The store should have the org.');
  if (!edition) logger.debug('The store should have the edition.');
  if (!currentSession)
    throw new Error('The store should have the current session.');

  return {
    user: toRaw(user),
    permissions: toRaw(permissions),
    impersonation: toRaw(impersonation),
    org: toRaw(org),
    edition: toRaw(edition),
    cognitoIdentity: {
      accessToken: currentSession.accessToken?.jwtToken,
      refreshToken: currentSession.refreshToken?.token,
      idToken: currentSession.idToken?.jwtToken,
    },
  };
};

export const useMicroFrontendStore = defineStore('microFrontend', {
  state: (): State => ({
    microFrontends: [],
    activeMicroFrontend: null,
    navigateTo: null,
    dropdown: {} as DropdownInterface,
  }),
  actions: {
    processReceivedMessage(event: MessageEvent) {
      const { data, source } = event;
      if (!source) throw new Error('Cannot communicate without source window.');
      const { operation, parameters } = data;
      if (operation && validChildOperations.includes(operation)) {
        logger.debug('[Navigation-UI] received message:', data);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this[operation](parameters, source);
      }
    },
    sendToChild(eventData: EventData, possibleMicroFrontend?: MicroFrontend) {
      const microFrontend = possibleMicroFrontend || this.activeMicroFrontend;
      if (!microFrontend)
        throw new Error(
          'There is either no active micro frontend or no valid micro frontend has been provided.'
        );
      if (!microFrontend.hasHandshakeCompleted)
        throw new Error(
          `Cannot communicate with micro frontend located at ${microFrontend.specification.basePath} because handshake has not been completed yet.`
        );
      logger.debug('[Navigation-UI] sending to child:', {
        ...eventData,
        microFrontendName: microFrontend.specification.name,
      });
      microFrontend.contentWindow.postMessage(eventData, TARGET_ORIGIN);
    },
    /**
     * Operation Actions -
     * processReceivedMessage -> this[operation](parameters, source);
     * [operation] here will call one of these actions below
     */
    startHandShake(_parameters: undefined, window: Window) {
      const microFrontend = this.microFrontends.find(
        (microFrontend) => microFrontend.contentWindow === window
      );
      if (!microFrontend)
        throw new Error(
          'There is no micro frontend suitable for the source window.'
        );
      microFrontend.hasHandshakeCompleted = true;
      this.sendToChild(
        { operation: 'completeHandShake', parameters: handShakeEventData() },
        microFrontend
      );
    },
    setMenuItems(eventData: SetMenuItemsData) {
      useNavBarStore().setMenuItems(eventData);
    },
    setCurrentUserNps(nps: any) {
      useUserDataStore().setUserNps(nps);
    },
    navigate(eventData: NavigateEventData) {
      this.navigateTo = eventData.to;
    },
    completeSignOut() {
      useNavBarStore().setReadyToNavigate(false);
      useNavBarStore().logOutUser();
      this.activeMicroFrontend = null;
      this.microFrontends.splice(0);
      this.forceAuthentication();
    },
    setSentryUser({ sub, username }: MeEventData) {
      /**
       * set the user to be the current authenticated user
       * ip_address: "{{auto}}" means that Sentry will determine the IP
       */
      Sentry.setUser({
        id: sub,
        email: username,
        ip_address: '{{auto}}',
      });
    },
    setReadyToNavigate(readyToNavigate: boolean) {
      useNavBarStore().setReadyToNavigate(readyToNavigate);
    },
    /**
     * End of
     * Operation Actions
     */
    clearHandShake() {
      this.microFrontends.forEach(
        (microFrontend) => (microFrontend.hasHandshakeCompleted = false)
      );
    },
    forceAuthentication() {
      window.location.href = '/identity/auth';
    },
    pushHistory(eventData: PushHistoryEventData) {
      if (eventData.path.includes('sign-out')) return;
      this.sendToChild({
        operation: 'pushHistory',
        parameters: eventData,
      });
    },
    hideDropdown() {
      this.dropdown.isVisible() && this.dropdown.hide();
    },
    addMicroFrontend(
      frame: HTMLIFrameElement,
      specification: MicroFrontendSpecification
    ) {
      const contentWindow = frame.contentWindow;
      if (!contentWindow) {
        throw new Error(
          'Cannot communicate with child without content window.'
        );
      }
      /**
       * Check for Existing
       * Check to see if the frontend we are adding already exists with the same base path.
       * If so, we are going to remove it and then add our new one.
       */
      const indexOfEquivalent = this.microFrontends.findIndex(
        (microFrontend) =>
          microFrontend.specification.basePath === specification.basePath
      );
      if (indexOfEquivalent != -1)
        this.microFrontends.splice(indexOfEquivalent, 1);
      /**
       * END Check for Existing
       */
      const microFrontend = {
        contentWindow,
        hasHandshakeCompleted: false,
        specification,
      };
      this.microFrontends.push(microFrontend);
    },
    setActiveMicroFrontend(microFrontend: MicroFrontend) {
      this.activeMicroFrontend = microFrontend;
    },
    isActiveMicroFrontend(microFrontend: MicroFrontend) {
      return this.activeMicroFrontend === microFrontend;
    },
    setDropdown(dropdown: DropdownInterface) {
      this.dropdown = dropdown;
    },
  },
});
